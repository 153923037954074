<template>
  <el-dialog
    title="导入进度"
    :visible.sync="show"
    class="choose-selectedPlan-importProgress"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :beforeClose="beforeClose"
    append-to-body
    width="600px"
  >
    <div class="main flex">
      <div class="icon icon-importing"></div>
      <div class="time">剩余时间：{{progress.wait_time}}秒，已用时：{{progress.past_seconds}}秒</div>
      <div class="text">导入数据共{{progress.total}},已处理{{progress.handle}}条，失败{{progress.bad}}条，正在导入，请稍后...</div>
      <el-button type="primary" size="small" @click="toBackground">后台执行</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  // 导入进度
  name: 'importProgress',
  props: {
    title: {
      type: String,
    },
    progress: Object,
    background: Boolean,
  },
  components: {},
  data() {
    return {
      show: false,
      timeout: '',
    };
  },
  methods: {
    beforeClose(done) {
      done();
      this.toBackground();
    },
    // 后台执行
    toBackground() {
      clearInterval(this.timeout);
      this.$emit('changeBackground', true);
      this.$store.commit('changeComponent', '');
    },
    toggle(show) {
      this.show = show;
    },
  },
  mounted() {},
  created() {
    // this.timeout = setInterval(() => {
    //   if (!this.background) {
    //     this.$emit('clear');
    //     clearInterval(this.timeout);
    //     this.$store.commit('changeComponent', 'importSuccess');
    //   }
    // }, 1000);
  },
  computed: {},
  watch: {
    'progress.finish': {
      handler: function (n) {
        if (n) {
          // this.$store.commit('changeComponent', 'importSuccess');
          this.$emit('closeDialog')
        }
      },
      immediate: true,
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-dialog__title {
  font-weight: bold;
}
.el-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .main {
    flex-direction: column;
    .icon {
      font-size: 64px;
      transition: roll 0.3s linear;
      animation: roll 3s linear infinite;
    }
    .time {
      margin-top: 30px;
    }
    .text {
      margin-top: 20px;
      margin-bottom: 50px;
    }
  }
}
@keyframes roll {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
::v-deep .el-dialog__title {
  font-weight: bold;
}
</style>
